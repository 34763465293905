import {http} from './config';

export default {
    create: (userAllotment, allotmentId) => {
        return http.post('api/v1/usuariosLoteamentos', userAllotment, {headers: {'allotment-id': allotmentId}});
    },
    update:(userAllotment) => {
        return http.put('api/v1/usuariosLoteamentos/' + userAllotment.id, userAllotment);
    },
    updateIsRealtor:(userAllotment) => {
        return http.put('api/v1/usuariosLoteamentos/isRealtor/' + userAllotment.id, userAllotment);
    },
    getAllotmentsById:(user_id) => {
        return http.get('api/v1/usuariosLoteamentos/listarPorIdUsuario/' + user_id);
    },
    listCompanyAllotment:() => {
        return http.get('api/v1/usuariosLoteamentos/companias-loteamentos-admin');
    },
    updatePermission:(isRealtor) => {
        return http.put('api/v1/usuariosLoteamentos/isRealtor', isRealtor);
    },
    listBrokesByAllotment:(allotmentId) => {
        return http.get('api/v1/loteamentos/usuarios/' + allotmentId);
    },
    activeMultiplesUsers:(data, allotmentId) =>{
        return http.post('api/v1/usuariosLoteamentos/atualizarMultiplos', data, {headers:
                    {'allotment-id': allotmentId}});
    }
}
